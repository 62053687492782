import { Accent } from '@/lib';
import { useGetWebGebruikers, useRequestAzureAccount as useCreateAzureAccount, useReset2FAAzureAccount } from '@/lib/queries/config-api/webGebruiker';
import { BasicTableColumn } from '@/lib/types';
import { WebGebruikerOutput } from '@/lib/types/webgebruiker';
import { BasicTable } from '@/lib/components/tables/BasicTable';
import { CheckCircleOutlined, CloudOutlined, DownOutlined, LockOutlined } from '@ant-design/icons';
import { App, Badge, Button, Card, Col, Dropdown, Modal, Row, Space } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MenuProps } from 'antd/lib';

export const WebGebruikerOverview: React.FunctionComponent = () => {
  const { t } = useTranslation('core');
  const { mutateAsync: reset2FAAzureAccount } = useReset2FAAzureAccount();
  const { mutateAsync: createAzureAccount } = useCreateAzureAccount();
  const [is2FAResetLoading, setIs2FAResetLoading] = useState(false);
  const [isAccountCreateLoading, setIsAccountCreateLoading] = useState(false);
  const [is2FAResetModalOpen, setIs2FAResetModalOpen] = useState(false);
  const [isAccountCreateModalOpen, setIsAccountCreateModalOpen] = useState(false);
  const navigate = useNavigate();
  const [selection, setSelection] = useState<WebGebruikerOutput[]>([]);
  const { message } = App.useApp();

  const columns: BasicTableColumn<WebGebruikerOutput>[] = [
    { label: 'webGebruiker.loginNaam', dataIndex: 'loginNaam', sortable: true, filterable: true },
    { label: 'webGebruiker.actief', dataIndex: 'actief', render: (value) => (value ? 'Ja' : 'Nee') },
    { label: 'webGebruiker.email', dataIndex: 'email', filterable: true, sortable: true },
    { label: 'webGebruiker.contactpersoon', dataIndex: 'contactpersoon', filterable: true, sortable: true },
    { label: 'webGebruiker.functieContactpersoon', dataIndex: 'functieContactpersoon', filterable: true, sortable: true },
    { label: 'webGebruiker.ondertekenaar', dataIndex: 'ondertekenaar', filterable: true, sortable: true },
    { label: 'webGebruiker.functieOndertekenaar', dataIndex: 'functieOndertekenaar', filterable: true, sortable: true },
    {
      label: 'webGebruiker.azureSubjectId', dataIndex: 'azureSubjectId', render: (value) =>
        value ? (
          <Accent color="success">
            <CheckCircleOutlined />
          </Accent>
        ) : null
    },
  ]

  const bulkDropdownItems: MenuProps['items'] = [
    {
      key: '1',
      label: 'Maak inlogaccount',
      icon: <CloudOutlined />,
      onClick: () => setIsAccountCreateModalOpen(true),
      disabled: !selection.length
    },
    {
      key: '2',
      label: 'Herregistratie 2FA vereisen',
      icon: <LockOutlined />,
      onClick: () => setIs2FAResetModalOpen(true),
      disabled: !selection.length
    },
  ]

  const reset2FAAzureAccounts = async () => {
    try {
      setIs2FAResetLoading(true);
      for (const user of selection) {
        if (user.azureSubjectId) {
          await reset2FAAzureAccount(user.azureSubjectId);
        } else {
          message.error(`Resetten voor gebruiker ${user.email} is niet gelukt`)
        }
      }
      message.success('2FA verificatiemethodes succesvol verwijderd');
    } catch (e) {
      message.error('Fout tijdens verwijderen 2FA verificatiemethode. Neem contact op met de servicedesk.');
    } finally {
      setIs2FAResetLoading(false);
      setIs2FAResetModalOpen(false);
      setSelection([]);
    }
  };

  const createAzureAccounts = async () => {
    try {
      setIsAccountCreateLoading(true);
      for (const user of selection) {
        if (user.azureSubjectId) {
          continue;
        }
        await createAzureAccount(user.id);
      }
      message.success('Succesvol aangemaakt');
    } catch (e) {
      message.error('Fout tijdens aanmaken accounts, probeer opnieuw');
    } finally {
      setIsAccountCreateLoading(false);
      setIsAccountCreateModalOpen(false);
      setSelection([]);
    }
  };

  return (
    <>
      <Card
        title="Web gebruikers overzicht"
        bordered={true}
        extra={
          <Row gutter={16}>
            <Col>
              <Button type="primary" onClick={() => navigate('create')}>
                Nieuwe aanmaken
              </Button>
            </Col>
            <Col>
              <Badge count={selection.length}>
                <Dropdown disabled={!selection.length} menu={{ items: bulkDropdownItems }}>
                  <Button onClick={(e) => e.preventDefault()}>
                    <Space>
                      Bulkacties
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </Badge>
              <Modal
                open={isAccountCreateModalOpen}
                confirmLoading={isAccountCreateLoading}
                title={'Maak inlogaccount'}
                onOk={() => createAzureAccounts()}
                onCancel={() => setIsAccountCreateModalOpen(false)}>
                {t('webGebruiker.createAzureAccountModal')}
              </Modal>
              <Modal
                open={is2FAResetModalOpen}
                confirmLoading={is2FAResetLoading}
                title={'Herregistratie 2FA vereisen'}
                onOk={() => reset2FAAzureAccounts()}
                onCancel={() => setIs2FAResetModalOpen(false)}>
                {t('webGebruiker.reregister2faModal')}
              </Modal>
            </Col>
          </Row>
        }>
        <BasicTable<WebGebruikerOutput> loadEntries={useGetWebGebruikers} columns={columns} onEdit={(id) => navigate(id.toString())} setSelection={setSelection} selection={selection} />
      </Card>
    </>
  );
};