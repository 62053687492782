import { ClusterForm } from '@/lib/components/forms/ClusterForm';
import { useGetClusterById, useGetClusterTypes, useGetEmissieGroepen, useGetGebruiksNormGroepenFosfor, useGetGebruiksNormGroepenStikstof, useGetGewasClusterEmissie, useGetGewasClusterGerbruik, useGetSectoren, useGetSignaalGroepenFosfor, useGetSignaalGroepenStikstof, useUpdateCluster } from '@/lib/queries/config-api/cluster';
import { Button, Card } from 'antd';
import Spin from 'antd/lib/spin';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const ClusterEdit: React.FunctionComponent = () => {
  const id = Number(useParams().id?.toString());
  const updateCluster = useUpdateCluster(id);
  const navigate = useNavigate();

  const { data: clusterById, isLoading: isClusterByIdLoading } = useGetClusterById(id);

  const formId = "updateClusterForm"

  return (
    <>
      <Card
        title="Cluster aanpassen"
        bordered={true}
        loading={isClusterByIdLoading}
        extra={
          <Button type="primary" htmlType="submit" form={formId}>
            Opslaan
          </Button>
        }>
        <ClusterForm
          id={formId}
          initialValues={{
            code: clusterById?.code,
            omschrijving: clusterById?.omschrijving,
            signaalgroepFosforId: clusterById?.signaalgroepFosforId,
            signaalgroepStikstofId: clusterById?.signaalgroepStikstofId,
            gebruiksNormgroepStikstofId: clusterById?.gebruiksNormgroepStikstofId,
            gebruiksNormgroepFosforId: clusterById?.gebruiksNormgroepFosforId,
            emissiegroepId: clusterById?.emissiegroepId,
            clusterTypeId: clusterById?.clusterTypeId,
            sectorId: clusterById?.sectorId,
            isBelichting: clusterById?.isBelichting,
            isOnbeteeld: clusterById?.isOnbeteeld,
            gewasClusterEmissieId: clusterById?.gewasClusterEmissieId,
            gewasClusterGebruikId: clusterById?.gewasClusterGebruikId,
            metAssimilatieBelichting: clusterById?.metAssimilatieBelichting,
            sectorTpdlId: clusterById?.sectorTpdlId,
          }}
          onFinish={values => {
            updateCluster.mutate({ ...values }, {
              onSuccess: () => {
                navigate('/cluster');
              },
            });
          }}
        />
      </Card>
    </>
  );
};