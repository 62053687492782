import './form.scss';
import { Col, Divider, Form, Input, InputNumber, Select, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import { Store } from 'antd/es/form/interface';
import { CustomDatePicker } from '@/lib/components/core/custom-datepicker/CustomDatepicker';
import { BevoegdGezagOutput, DeelnemerActiefEnum, DeelnemerInput, DeelnemerOutput, ZuiveringsplichtVanToepassingEnum, ZuiveringsplichtwijzeEnum } from '@/lib/types';
import { constants } from '@/lib/constants';

export interface DeelnemerFormProps {
  onFinish: (values: any) => void | undefined;
  hoofdvestigingen: DeelnemerOutput[];
  bevoegdGezagen: BevoegdGezagOutput[];
  initialValues?: Store | undefined;
  id?: string | undefined;
}

export const DeelnemerForm: React.FunctionComponent<DeelnemerFormProps> = ({ onFinish, bevoegdGezagen, hoofdvestigingen, initialValues, id }) => {
  const [form] = Form.useForm<DeelnemerInput>();
  const { t } = useTranslation('core');

  const isHoofdvestigingSelected = Form.useWatch('isHoofdvestiging', form)
  const isMaatwerkvoorschriftBerekenenSelected = Form.useWatch('maatwerkvoorschriftMagBerekenen', form)
  const isZuiveringsplichtSelected = [1].includes(Form.useWatch('zuiveringsplichtVanToepassing', form))

  const formItemLayout = { labelCol: { span: 10 }, wrapperCol: { span: 10 } };

  return (
    <Form
      id={id}
      className="form oneColumn"
      {...formItemLayout}
      layout={'horizontal'}
      form={form}
      initialValues={initialValues ?? { isHoofdvestiging: true, maatwerkvoorschriftMagBerekenen: false }}
      onFinish={onFinish}>
      {/* Deelnemer */}{' '}
      <Form.Item label={t('deelnemer.bedrijfsnaam')} name="bedrijfsnaam" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item label={t('deelnemer.uONummer')} name="uoNummer" rules={[{ required: true }]}>
        <InputNumber controls={false} />
      </Form.Item>
      <Form.Item label={t('deelnemer.contactPersoon')} name="contactPersoon" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item label={t('deelnemer.email')} name="email" rules={[{ required: true, type: 'email' }]}>
        <Input />
      </Form.Item>
      <Form.Item label={t('deelnemer.gemeente')} name="gemeenteID" rules={[{ required: true }]}>
        <Select>
          {(bevoegdGezagen as BevoegdGezagOutput[])
            .filter(x => x.bevoegdGezagTypeId === constants.gemeenteRolId)
            .map((item, index) => (
              <Select.Option key={index} value={item.id}>
                {item.volledigeNaam}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item label={t('deelnemer.nrGemeente')} name="nrGemeente" rules={[{ required: false }]}>
        <InputNumber stringMode={true} controls={false} />
      </Form.Item>
      <Form.Item label={t('deelnemer.waterschap')} name="waterschapID" rules={[{ required: true }]}>
        <Select>
          {(bevoegdGezagen as BevoegdGezagOutput[])
            .filter(x => x.bevoegdGezagTypeId === constants.milieudienstRolId)
            .map((item, index) => (
              <Select.Option key={index} value={item.id}>
                {item.volledigeNaam}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item label={t('deelnemer.nrWaterschap')} name="nrWaterschap" rules={[{ required: false }]}>
        <InputNumber stringMode={true} controls={false} />
      </Form.Item>
      <Form.Item label={t('deelnemer.deelnemerActief')} name="deelnemerActief" rules={[{ required: true }]}>
        <Select
          options={Object.values(DeelnemerActiefEnum)
            .filter(v => !isNaN(Number(v)))
            .map((item, _) => {
              return { value: item, label: DeelnemerActiefEnum[item] };
            })}
        />
      </Form.Item>
      <Form.Item label={t('deelnemer.eindDatum')} name="eindDatum" rules={[{ required: false }]}>
        <CustomDatePicker style={{ width: '100%' }} picker="date" />
      </Form.Item>

      <Form.Item label={t('deelnemer.isHoofdvestiging')} name="isHoofdvestiging" >
        <Switch />
      </Form.Item>
      <Form.Item label={t('deelnemer.parent')} hidden={isHoofdvestigingSelected} name="parent" rules={[{ required: false }]}>
        <Select>
          {(hoofdvestigingen as DeelnemerOutput[]).map((item, index) => (
            <Select.Option key={index} value={item.id}>
              {item.bedrijfsnaam}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Divider>Vestigingsgegevens</Divider>
      <br />
      {/* Postadres */}
      <Form.Item label={t('deelnemer.vestigingsStraat')} name="vestigingsStraat" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item label={t('deelnemer.vestigingsNummer')} name="vestigingsNummer" rules={[{ required: true }]}>
        <InputNumber stringMode={true} controls={false} />
      </Form.Item>
      <Form.Item label={t('deelnemer.vestigingsNummerToevoeging')} name="vestigingsNummerToevoeging" rules={[{ required: false }]}>
        <Input />
      </Form.Item>
      <Form.Item label={t('deelnemer.vestigingsPlaats')} name="vestigingsPlaats" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item label={t('deelnemer.vestigingsPostcodeCijfers')} name="vestigingsPostcodeCijfers" rules={[{ required: true }]}>
        <InputNumber stringMode={true} minLength={4} controls={false} />
      </Form.Item>
      <Form.Item label={t('deelnemer.vestigingsPostcode')} name="vestigingsPostcode" rules={[{ required: true }]}>
        <Input minLength={2} />
      </Form.Item>
      <Divider>Persoonsgegevens</Divider>
      <br />
      <Form.Item label={t('deelnemer.straat')} name="straat" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item label={t('deelnemer.huisNummer')} name="huisNummer" rules={[{ required: true }]}>
        <InputNumber stringMode={true} controls={false} />
      </Form.Item>
      <Form.Item label={t('deelnemer.huisNummerToevoeging')} name="huisNummerToevoeging" rules={[{ required: false }]}>
        <Input />
      </Form.Item>
      <Form.Item label={t('deelnemer.plaats')} name="plaats" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item label={t('deelnemer.postadresPostcodeCijfers')} name="postadresPostcodeCijfers" rules={[{ required: true }]}>
        <InputNumber stringMode={true} minLength={4} controls={false} />
      </Form.Item>
      <Form.Item label={t('deelnemer.postcode')} name="postcode" rules={[{ required: true }]}>
        <Input minLength={2} />
      </Form.Item>
      <Form.Item label={t('deelnemer.telefoonnummer')} name="telefoonnummer" rules={[{ required: true }]}>
        <InputNumber stringMode={true} minLength={10} controls={false} />
      </Form.Item>
      <Divider>Zuiveringsplicht</Divider>
      <br />
      <Form.Item label={t('deelnemer.zuiveringsplichtVanToepassing')} name="zuiveringsplichtVanToepassing" rules={[{ required: false }]}>
        <Select
          options={Object.values(ZuiveringsplichtVanToepassingEnum)
            .filter(v => !isNaN(Number(v)))
            .map((item, _) => {
              return { value: item, label: ZuiveringsplichtVanToepassingEnum[item] };
            })}
        />
      </Form.Item>
      <Form.Item label={t('deelnemer.zuiveringsplichtwijze')} hidden={!isZuiveringsplichtSelected} name="zuiveringsplichtWijze" rules={[{ required: isZuiveringsplichtSelected }]}>
        <Select
          options={Object.values(ZuiveringsplichtwijzeEnum)
            .filter(v => !isNaN(Number(v)))
            .map((item, _) => {
              return { value: item, label: ZuiveringsplichtwijzeEnum[item] };
            })}
        />
      </Form.Item>
      <Divider>Maatwerkvoorschrift</Divider>
      <br />
      <Form.Item
        label={t('deelnemer.maatwerkvoorschriftMagBerekenen')}
        name="maatwerkvoorschriftMagBerekenen"
      >
        <Switch />
      </Form.Item>
      <Form.Item label={t('deelnemer.maatwerkvoorschriftTeeltwijzeCode')} hidden={!isMaatwerkvoorschriftBerekenenSelected} name="maatwerkvoorschriftTeeltwijzeCode" rules={[{ required: isMaatwerkvoorschriftBerekenenSelected }]}>
        <InputNumber stringMode={true} controls={false} />
      </Form.Item>
      <Form.Item label={t('deelnemer.jaartalMaatwerkVoorschriftToegestaan')} hidden={!isMaatwerkvoorschriftBerekenenSelected} name="jaartalMaatwerkVoorschriftToegestaan" rules={[{ required: isMaatwerkvoorschriftBerekenenSelected }]}>
        <InputNumber stringMode={true} controls={false} />
      </Form.Item>
      <Form.Item label={t('deelnemer.maatwerkvoorschriftDoor')} hidden={!isMaatwerkvoorschriftBerekenenSelected} name="maatwerkvoorschriftDoor" rules={[{ required: isMaatwerkvoorschriftBerekenenSelected }]}>
        <Input />
      </Form.Item>
      <Form.Item label={t('deelnemer.toelichting')} hidden={!isMaatwerkvoorschriftBerekenenSelected} name="toelichting" rules={[{ required: false }]}>
        <Input />
      </Form.Item>
    </Form>
  );
};