import './form.scss';
import { Button, Divider, Form, Input, InputNumber, Select, Spin, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import { Store } from 'antd/es/form/interface';
import { Cluster, ClusterType, EmissieGroep, GebruiksNormgroepFosfor, GebruiksNormgroepStikstof, GewasclusterEmissie, GewasclusterGebruik, Sector, SignaalgroepFosfor, SignaalgroepStikstof } from '@/lib/types/cluster';
import { PlusOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { SignaalFosforModal } from '../modals/SignaalFosforModal';
import { useGetClusterTypes, useGetEmissieGroepen, useGetGebruiksNormGroepenFosfor, useGetGebruiksNormGroepenStikstof, useGetGewasClusterEmissie, useGetGewasClusterGerbruik, useGetSectoren, useGetSignaalGroepenFosfor, useGetSignaalGroepenStikstof } from '@/lib/queries/config-api/cluster';
import { SignaalStikstofModal } from '../modals/SignaalStikstofModal';
import { NormFosforModal } from '../modals/NormFosforModal';
import { NormStikstofModal } from '../modals/NormStikstofModal';

export interface ClusterFormProps {
  onFinish: (values: any) => void | undefined;
  initialValues?: Store | undefined;
  id?: string | undefined;
}

enum GroepEnum {
  SignaalFosfor,
  SignaalStikstof,
  NormStikstof,
  NormFosfor
}

export const ClusterForm: React.FunctionComponent<ClusterFormProps> = ({
  onFinish,
  initialValues,
  id,
}) => {
  const { t } = useTranslation('core');
  const [form] = Form.useForm<Cluster>();

  const [modalType, setModalType] = useState<GroepEnum | null>(null);

  const { data: signaalGroepenFosfor, isLoading: isSignaalGroepenFosforLoading, refetch: refetchSignaalGroepenFosfor } = useGetSignaalGroepenFosfor();
  const { data: signaalGroepenStikstof, isLoading: isSignaalGroepenStikstofLoading, refetch: refetchSignaalGroepenStikstof } = useGetSignaalGroepenStikstof();
  const { data: gebruiksNormGroepenFosfor, isLoading: isGebruiksNormGroepenFosforLoading, refetch: refetchNormGroepenFosfor } = useGetGebruiksNormGroepenFosfor();
  const { data: gebruiksNormGroepenStikstof, isLoading: isGebruiksNormGroepenStikstofLoading, refetch: refetchNormGroepenStikstof } = useGetGebruiksNormGroepenStikstof();
  const { data: emissieGroepen, isLoading: isEmissieGroepen } = useGetEmissieGroepen();
  const { data: clusterTypes, isLoading: isClusterTypesLoading } = useGetClusterTypes();
  const { data: sectoren, isLoading: isSectorenLoading } = useGetSectoren();
  const { data: gewasClustersEmissie, isLoading: isGewasClustersEmissieLoading } = useGetGewasClusterEmissie();
  const { data: gewasClustersGebruik, isLoading: isGewasClustersGebruikLoading } = useGetGewasClusterGerbruik()

  const formItemLayout = { labelCol: { span: 10 }, wrapperCol: { span: 10 } };

  const showModal = (groepEnum: GroepEnum) => {
    setModalType(groepEnum);
  };

  return (
    <>
      {isSectorenLoading || isGewasClustersEmissieLoading || isGewasClustersGebruikLoading || isClusterTypesLoading || isEmissieGroepen || isGebruiksNormGroepenStikstofLoading || isGebruiksNormGroepenFosforLoading || isSignaalGroepenFosforLoading || isSignaalGroepenStikstofLoading ? (
        <Spin size="large" />
      ) : (
        <>
          <Form
            id={id}
            className="form oneColumn"
            {...formItemLayout}
            layout={'horizontal'}
            form={form}
            initialValues={initialValues}
            onFinish={onFinish}>

            <Form.Item label={t('cluster.code')} name="code" rules={[{ required: true }]}>
              <InputNumber controls={false} />
            </Form.Item>

            <Form.Item label={t('cluster.omschrijving')} name="omschrijving" rules={[{ required: true }]}>
              <Input />
            </Form.Item>

            <Form.Item label={t('cluster.clusterTypes')} name="clusterTypeId" rules={[{ required: true }]}>
              <Select>
                {(clusterTypes as ClusterType[] ?? []).map((item, index) => (
                  <Select.Option key={index} value={item.id}>
                    {item.omschrijving}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label={t('cluster.sectoren')} name="sectorId" rules={[{ required: true }]}>
              <Select>
                {(sectoren as Sector[] ?? []).map((item, index) => (
                  <Select.Option key={index} value={item.id}>
                    {item.omschrijving}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Divider>Signaalgroepen</Divider>
            <br />

            <Form.Item label={t('cluster.signaalGroepFosfor')} style={{ marginBottom: 0 }} required>
              <Form.Item name="signaalgroepFosforId" style={{ display: 'inline-block', minWidth: '60%' }} rules={[{ required: true }]}>
                <Select >
                  {(signaalGroepenFosfor as SignaalgroepFosfor[] ?? []).map((item, index) => (
                    <Select.Option key={index} value={item.id}>
                      {item.waarde}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item style={{ display: 'inline-block' }}>
                <Button onClick={() => showModal(GroepEnum.SignaalFosfor)} icon={<PlusOutlined />} style={{ margin: '0 8px' }} />
              </Form.Item>
            </Form.Item>

            <Form.Item label={t('cluster.signaalGroepStikstof')} style={{ marginBottom: 0 }} required>
              <Form.Item name="signaalgroepStikstofId" style={{ display: 'inline-block', minWidth: '60%' }} rules={[{ required: true }]}>
                <Select>
                  {(signaalGroepenStikstof as SignaalgroepStikstof[] ?? []).map((item, index) => (
                    <Select.Option key={index} value={item.id}>
                      {item.waarde}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item style={{ display: 'inline-block' }}>
                <Button onClick={() => showModal(GroepEnum.SignaalStikstof)} icon={<PlusOutlined />} style={{ margin: '0 8px' }} />
              </Form.Item>
            </Form.Item>


            <Divider>Gebruiksnormgroepen</Divider>
            <br />

            <Form.Item label={t('cluster.gebruiksNormgroepStikstof')} style={{ marginBottom: 0 }} required>
              <Form.Item name="gebruiksNormgroepStikstofId" style={{ display: 'inline-block', minWidth: '60%' }} rules={[{ required: true }]}>
                <Select >
                  {(gebruiksNormGroepenStikstof as GebruiksNormgroepStikstof[] ?? []).map((item, index) => (
                    <Select.Option key={index} value={item.id}>
                      {item.waarde}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item style={{ display: 'inline-block' }}>
                <Button onClick={() => showModal(GroepEnum.NormStikstof)} icon={<PlusOutlined />} style={{ margin: '0 8px' }} />
              </Form.Item>
            </Form.Item>

            <Form.Item label={t('cluster.gebruiksNormgroepFosfor')} style={{ marginBottom: 0 }} required>
              <Form.Item name="gebruiksNormgroepFosforId" style={{ display: 'inline-block', minWidth: '60%' }} rules={[{ required: true }]}>
                <Select >
                  {(gebruiksNormGroepenFosfor as GebruiksNormgroepFosfor[] ?? []).map((item, index) => (
                    <Select.Option key={index} value={item.id}>
                      {item.waarde}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item style={{ display: 'inline-block' }}>
                <Button onClick={() => showModal(GroepEnum.NormFosfor)} icon={<PlusOutlined />} style={{ margin: '0 8px' }} />
              </Form.Item>
            </Form.Item>

            <Divider>Emissies</Divider>
            <br />

            <Form.Item label={t('cluster.emissieGroepen')} name="emissiegroepId" rules={[{ required: true }]}>
              <Select>
                {(emissieGroepen as EmissieGroep[] ?? []).map((item, index) => (
                  <Select.Option key={index} value={item.id}>
                    {item.omschrijving}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label={t('cluster.gewasClusterGebruik')} name="gewasClusterGebruikId" rules={[{ required: true }]}>
              <Select>
                {(gewasClustersGebruik as GewasclusterGebruik[] ?? []).map((item, index) => (
                  <Select.Option key={index} value={item.id}>
                    {item.omschrijving}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label={t('cluster.gewasClusterEmissie')} name="gewasClusterEmissieId" rules={[{ required: true }]}>
              <Select>
                {(gewasClustersEmissie as GewasclusterEmissie[] ?? []).map((item, index) => (
                  <Select.Option key={index} value={item.id}>
                    {item.omschrijving}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Divider>Specifieke kenmerken</Divider>
            <br />

            <Form.Item label={t('cluster.isBelichting')} name="isBelichting" >
              <Switch />
            </Form.Item>

            <Form.Item label={t('cluster.metAssimilatiebelichting')} name="metAssimilatieBelichting" >
              <Switch />
            </Form.Item>

            <Form.Item label={t('cluster.isOnbeteeld')} name="isOnbeteeld" >
              <Switch />
            </Form.Item>

            <Form.Item label={t('cluster.teeltplanSectorId')} name="sectorTpdlId" rules={[{ required: true }]}>
              <Input />
            </Form.Item>

          </Form>

          <SignaalFosforModal
            visible={modalType === GroepEnum.SignaalFosfor}
            onClose={() => setModalType(null)}
            onSave={() => refetchSignaalGroepenFosfor()}
          />

          <SignaalStikstofModal
            visible={modalType === GroepEnum.SignaalStikstof}
            onClose={() => setModalType(null)}
            onSave={() => refetchSignaalGroepenStikstof()}
          />

          <NormFosforModal
            visible={modalType === GroepEnum.NormFosfor}
            onClose={() => setModalType(null)}
            onSave={() => refetchNormGroepenFosfor()}
          />

          <NormStikstofModal
            visible={modalType === GroepEnum.NormStikstof}
            onClose={() => setModalType(null)}
            onSave={() => refetchNormGroepenStikstof()}
          />
        </>
      )}
    </>
  );
};