import { WebGebruikerForm } from '@/lib/components/forms/WebGebruikerForm';
import { constants } from '@/lib/constants';
import { useGetBevoegdGezagen, useGetMilieudiensten } from '@/lib/queries';
import { useGetDeelnemers } from '@/lib/queries/config-api/deelnemer';
import { useGetDeskundigen } from '@/lib/queries/config-api/deskundige';
import { useGetWebgebruikerById, useUpdateWebgebruiker } from '@/lib/queries/config-api/webGebruiker';
import { Button, Card, Spin } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

export const WebGebruikerEdit: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const id = Number(useParams().id?.toString());
  const updateWebgebruiker = useUpdateWebgebruiker(id);

  const { data: webGebruikerResponse, isLoading: isWebGebruikerLoading } = useGetWebgebruikerById(id);
  const { data: milieudienstenResponse, isLoading: isMilieudienstenLoading } = useGetMilieudiensten({ pageSize: constants.defaultMaxPageSize });
  const { data: bevoegdGezagResponse, isLoading: isBevoegdGezagLoading } = useGetBevoegdGezagen({ pageSize: constants.defaultMaxPageSize });
  const { data: deelnemerResponse, isLoading: isDeelnemerLoading } = useGetDeelnemers({ pageSize: constants.defaultMaxPageSize });
  const { data: deskundigenResponse, isLoading: isDeskundigenLoading } = useGetDeskundigen({ pageSize: constants.defaultMaxPageSize });

  const formId = 'WebGebruikerEditForm';
  return (
    <>
      {isMilieudienstenLoading || isBevoegdGezagLoading || isDeelnemerLoading || isDeskundigenLoading || isWebGebruikerLoading ? (
        <Spin size="large" />
      ) : (
        <Card
          title="Web gebruiker aanpassen"
          bordered={true}
          extra={
            <Button type="primary" htmlType="submit" form={formId}>
              Opslaan
            </Button>
          }>
          <WebGebruikerForm
            id={formId}
            onFinish={values => {
              updateWebgebruiker.mutate(values, {
                onSuccess: () => {
                  navigate('/webgebruiker');
                },
              });
            }}
            initialValues={{
              contactpersoon: webGebruikerResponse?.contactpersoon,
              functieContactpersoon: webGebruikerResponse?.functieContactpersoon,
              ondertekenaar: webGebruikerResponse?.ondertekenaar,
              functieOndertekenaar: webGebruikerResponse?.functieOndertekenaar,
              webGebruikerGroepId: webGebruikerResponse?.webGebruikerGroepId,
              bevoegdgezagId: webGebruikerResponse?.bevoegdgezagId,
              deelnemerId: webGebruikerResponse?.deelnemerId,
              milieudienstId: webGebruikerResponse?.milieudienstId,
              deskundigeId: webGebruikerResponse?.deskundigeId,
              loginNaam: webGebruikerResponse?.loginNaam,
              email: webGebruikerResponse?.email
            }}
            initialWebGebruikerGroep={webGebruikerResponse?.webGebruikerGroepId}
            milieudiensten={milieudienstenResponse ?? []}
            deskundigen={deskundigenResponse ?? []}
            bevoegdGezagen={bevoegdGezagResponse?.data ?? []}
            deelnemers={deelnemerResponse?.data ?? []}
            isCreate={false}
          />
        </Card>
      )}
    </>
  );
};