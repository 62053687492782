import { DeelnemerForm } from '@/lib/components/forms/DeelnemerForm';
import { constants } from '@/lib/constants';
import { useGetBevoegdGezagen } from '@/lib/queries';
import { useCreateDeelnemer, useGetParentDeelnemers } from '@/lib/queries/config-api/deelnemer';
import { Button, Card, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const DeelnemerCreate: React.FunctionComponent = () => {
  const createDeelnemer = useCreateDeelnemer();
  const navigate = useNavigate();
  const { t } = useTranslation('core');

  const { data: bevoegdGezagResponse, isLoading: isBevoegdGezagLoading } = useGetBevoegdGezagen({ pageSize: constants.defaultMaxPageSize });
  const { data: parentResponse, isLoading: isParentLoading } = useGetParentDeelnemers();

  const formId = 'createDeelnemerForm';
  return (
    <>
      {isBevoegdGezagLoading || isParentLoading ? (
        <Spin size="large" />
      ) : (
        <Card
          title={t('deelnemer.create')}
          bordered={true}
          loading={isBevoegdGezagLoading || isParentLoading}
          extra={
            <Button type="primary" htmlType="submit" form={formId}>
              Opslaan
            </Button>
          }>
          <DeelnemerForm
            id={formId}
            onFinish={values => {
              createDeelnemer.mutate(
                {
                  ...values,
                },
                {
                  onSuccess: () => {
                    navigate('/deelnemer');
                  },
                }
              );
            }}
            bevoegdGezagen={bevoegdGezagResponse?.data ?? []}
            hoofdvestigingen={parentResponse ?? []}
          />
        </Card >
      )}
    </>
  );
};