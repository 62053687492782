import { BasicTableColumn } from '@/lib/types';
import { BasicTable } from '@/lib/components/tables/BasicTable';
import { Button, Card } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useGetCluster as useGetClusters } from '@/lib/queries/config-api/cluster';
import { Cluster } from '@/lib/types/cluster';

export const ClusterOverview: React.FunctionComponent = () => {
  const navigate = useNavigate();

  const columns: BasicTableColumn<Cluster>[] = [
    { label: 'cluster.code', dataIndex: 'code', filterable: true, sortable: true, width: 150 },
    { label: 'cluster.omschrijving', dataIndex: 'omschrijving', filterable: true, sortable: true, width: 150 },
    { label: 'cluster.sectoren', dataIndex: 'sector', filterable: false, sortable: false, width: 150 },
    { label: 'cluster.clusterTypes', dataIndex: 'clusterType', filterable: false, sortable: false, width: 150 },
  ];

  return (
    <Card
      title="Cluster overzicht"
      bordered={true}
      extra={
        <Button type="primary" onClick={() => navigate('create')}>
          Nieuwe aanmaken
        </Button>
      }>
      <BasicTable<Cluster> loadEntries={useGetClusters} columns={columns} onEdit={id => navigate(id.toString())} />
    </Card>
  );
};