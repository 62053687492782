import { Cluster, ClusterType, EmissieGroep, GebruiksNormgroepFosfor, GebruiksNormgroepStikstof, GewasclusterEmissie, GewasclusterGebruik, Sector, SignaalgroepFosfor, SignaalgroepStikstof } from '@/lib/types/cluster';
import { createFilterParams, fetchAuthenticated } from '@/lib/utilities/api';
import { PagedResponse, PaginationQueryParameters } from '@/shared/types/general';
import { UseQueryResult, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

const QUERY_KEY = ['clusters'];
const BASE_URL = `${import.meta.env.VITE_CONFIG_API_URL}/cluster`;

export const useGetCluster = (params?: PaginationQueryParameters<Cluster>): UseQueryResult<PagedResponse<Cluster>, Error> => {
  return useQuery({
    queryKey: [...QUERY_KEY, params],
    queryFn: () => fetchAuthenticated<PagedResponse<Cluster>>(`${BASE_URL}?${createFilterParams(params ?? {})}`),
  });
};

export const useGetClusterById = (id?: number): UseQueryResult<Cluster, Error> =>
  useQuery({
    queryKey: [...QUERY_KEY, id],
    queryFn: () => fetchAuthenticated<Cluster>(`${BASE_URL}/${id}`),
    enabled: !!id,
  });

export const useCreateCluster = () => {
  return useMutation<Cluster, Error, Cluster>({
    mutationFn: (newCluster: Cluster) =>
      fetchAuthenticated<Cluster>(`${BASE_URL}`, {
        json: newCluster,
        method: 'POST',
      }),
    mutationKey: [...QUERY_KEY],
  });
};

export const useUpdateCluster = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation<Cluster, Error, Cluster>({
    mutationFn: (newCluster: Cluster) =>
      fetchAuthenticated<Cluster>(`${BASE_URL}/${id}`, {
        json: newCluster,
        method: 'PUT',
      }),
    mutationKey: [...QUERY_KEY, id],
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [...QUERY_KEY, id] });
    },
  });
};

export const useGetSignaalGroepenFosfor = (params?: PaginationQueryParameters<Cluster>): UseQueryResult<SignaalgroepFosfor[], Error> => {
  return useQuery({
    queryKey: [...QUERY_KEY, 'SignaalgroepFosfor', params],
    queryFn: () => fetchAuthenticated<SignaalgroepFosfor[]>(`${BASE_URL}/signaalGroepenFosfor?${createFilterParams(params ?? {})}`),
  });
};

export const useCreateSignaalGroepFosfor = () => {
  return useMutation<SignaalgroepFosfor, Error, SignaalgroepFosfor>({
    mutationFn: (newSignaalgroepFosfor: SignaalgroepFosfor) =>
      fetchAuthenticated<SignaalgroepFosfor>(`${BASE_URL}/signaalGroepenFosfor`, {
        json: newSignaalgroepFosfor,
        method: 'POST',
      }),
    mutationKey: [...QUERY_KEY],
  });
};

export const useGetSignaalGroepenStikstof = (params?: PaginationQueryParameters<Cluster>): UseQueryResult<SignaalgroepStikstof[], Error> => {
  return useQuery({
    queryKey: [...QUERY_KEY, 'SignaalgroepStikstof', params],
    queryFn: () => fetchAuthenticated<SignaalgroepStikstof[]>(`${BASE_URL}/signaalGroepenStikstof?${createFilterParams(params ?? {})}`),
  });
};

export const useCreateSignaalGroepStikstof = () => {
  return useMutation<SignaalgroepStikstof, Error, SignaalgroepStikstof>({
    mutationFn: (newSignaalgroepStikstof: SignaalgroepStikstof) =>
      fetchAuthenticated<SignaalgroepStikstof>(`${BASE_URL}/signaalGroepenStikstof`, {
        json: newSignaalgroepStikstof,
        method: 'POST',
      }),
    mutationKey: [...QUERY_KEY],
  });
};

export const useGetGebruiksNormGroepenFosfor = (params?: PaginationQueryParameters<Cluster>): UseQueryResult<GebruiksNormgroepFosfor[], Error> => {
  return useQuery({
    queryKey: [...QUERY_KEY, 'GebruiksNormGroepFosfor', params],
    queryFn: () => fetchAuthenticated<GebruiksNormgroepFosfor[]>(`${BASE_URL}/gebruiksNormGroepenFosfor?${createFilterParams(params ?? {})}`),
  });
};

export const useCreateNormGroepFosfor = () => {
  return useMutation<GebruiksNormgroepFosfor, Error, GebruiksNormgroepFosfor>({
    mutationFn: (newNormgroepFosfor: GebruiksNormgroepFosfor) =>
      fetchAuthenticated<GebruiksNormgroepFosfor>(`${BASE_URL}/normGroepenFosfor`, {
        json: newNormgroepFosfor,
        method: 'POST',
      }),
    mutationKey: [...QUERY_KEY],
  });
};

export const useGetGebruiksNormGroepenStikstof = (params?: PaginationQueryParameters<Cluster>): UseQueryResult<GebruiksNormgroepStikstof[], Error> => {
  return useQuery({
    queryKey: [...QUERY_KEY, 'GebruiksNormgroepStikstof', params],
    queryFn: () => fetchAuthenticated<GebruiksNormgroepStikstof[]>(`${BASE_URL}/gebruiksNormGroepenStikstof?${createFilterParams(params ?? {})}`),
  });
};

export const useCreateNormGroepStikstof = () => {
  return useMutation<GebruiksNormgroepStikstof, Error, GebruiksNormgroepStikstof>({
    mutationFn: (newNormgroepStikstof: GebruiksNormgroepStikstof) =>
      fetchAuthenticated<GebruiksNormgroepStikstof>(`${BASE_URL}/normGroepenStikstof`, {
        json: newNormgroepStikstof,
        method: 'POST',
      }),
    mutationKey: [...QUERY_KEY],
  });
};

export const useGetEmissieGroepen = (params?: PaginationQueryParameters<Cluster>): UseQueryResult<EmissieGroep[], Error> => {
  return useQuery({
    queryKey: [...QUERY_KEY, 'EmissieGroep', params],
    queryFn: () => fetchAuthenticated<EmissieGroep[]>(`${BASE_URL}/emissieGroepen?${createFilterParams(params ?? {})}`),
  });
};

export const useGetClusterTypes = (params?: PaginationQueryParameters<Cluster>): UseQueryResult<ClusterType[], Error> => {
  return useQuery({
    queryKey: [...QUERY_KEY, 'ClusterType', params],
    queryFn: () => fetchAuthenticated<ClusterType[]>(`${BASE_URL}/clusterTypes?${createFilterParams(params ?? {})}`),
  });
};

export const useGetSectoren = (params?: PaginationQueryParameters<Cluster>): UseQueryResult<Sector[], Error> => {
  return useQuery({
    queryKey: [...QUERY_KEY, 'Sector', params],
    queryFn: () => fetchAuthenticated<Sector[]>(`${BASE_URL}/sectoren?${createFilterParams(params ?? {})}`),
  });
};

export const useGetGewasClusterEmissie = (params?: PaginationQueryParameters<Cluster>): UseQueryResult<GewasclusterEmissie[], Error> => {
  return useQuery({
    queryKey: [...QUERY_KEY, 'GewasclusterEmissie', params],
    queryFn: () => fetchAuthenticated<GewasclusterEmissie[]>(`${BASE_URL}/gewasClusterEmissies?${createFilterParams(params ?? {})}`),
  });
};

export const useGetGewasClusterGerbruik = (params?: PaginationQueryParameters<Cluster>): UseQueryResult<GewasclusterGebruik[], Error> => {
  return useQuery({
    queryKey: [...QUERY_KEY, 'GewasclusterGebruik', params],
    queryFn: () => fetchAuthenticated<GewasclusterGebruik[]>(`${BASE_URL}/gewasClusterGebruik?${createFilterParams(params ?? {})}`),
  });
};