import { useGetDeelnemers } from '@/lib/queries/config-api/deelnemer';
import { BasicTableColumn } from '@/lib/types';
import { DeelnemerOutput } from '@/lib/types/deelnemer';
import { BasicTable } from '@/lib/components/tables/BasicTable';
import { Button, Card, Table, TablePaginationConfig, TableProps } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const DeelnemerOverview: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('core');

  const columns: BasicTableColumn<DeelnemerOutput>[] = [
    { label: 'deelnemer.bedrijfsnaam', dataIndex: 'bedrijfsnaam', sortable: true, filterable: true },
    { label: 'deelnemer.contactPersoon', dataIndex: 'contactPersoon', sortable: true, filterable: true },
    { label: 'deelnemer.actief', dataIndex: 'actief', render: (value) => (value ? 'Ja' : 'Nee'), },
    { label: 'deelnemer.vestigingsStraat', dataIndex: 'vestigingsStraat', sortable: true, filterable: true },
    { label: 'deelnemer.vestigingsNummer', dataIndex: 'vestigingsNummer', sortable: true, filterable: true },
    { label: 'deelnemer.vestigingsNummerToevoeging', dataIndex: 'vestigingsNummerToevoeging', sortable: true, filterable: true },
    { label: 'deelnemer.vestigingsPostcodeCijfers', dataIndex: 'vestigingsPostcodeCijfers', sortable: true, filterable: true },
    { label: 'deelnemer.vestigingsPostcode', dataIndex: 'vestigingsPostcode', sortable: true, filterable: true },
    { label: 'deelnemer.vestigingsPlaats', dataIndex: 'vestigingsPlaats', sortable: true, filterable: true },
  ];

  return (
    <>
      <Card
        title={t('deelnemer.overview')}
        bordered={true}
        extra={
          <Button type="primary" onClick={() => navigate('create')}>
            Nieuwe deelnemer aanmaken
          </Button>
        }>
        <BasicTable<DeelnemerOutput> loadEntries={useGetDeelnemers} columns={columns} onEdit={id => navigate(id.toString())} />
      </Card>
    </>
  );
};