import { WebGebruikerInput, WebGebruikerOutput } from '@/lib/types/webgebruiker';
import { createFilterParams, fetchAuthenticated } from '@/lib/utilities/api';
import { PagedResponse, PaginationQueryParameters } from '@/shared/types/general';
import { UseMutationOptions, UseMutationResult, UseQueryResult, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

const QUERY_KEY = ['webGebruiker'];
const BASE_URL = `${import.meta.env.VITE_CONFIG_API_URL}/webgebruiker`;

export const useGetWebGebruikers = (params?: PaginationQueryParameters<WebGebruikerOutput>): UseQueryResult<PagedResponse<WebGebruikerOutput>, Error> => {
  return useQuery({
    queryKey: [...QUERY_KEY, params],
    queryFn: () => {
      return fetchAuthenticated<PagedResponse<WebGebruikerOutput>>(`${BASE_URL}?${createFilterParams(params ?? {})}`);
    },
  });
};

export const useCreateWebgebruiker = () => {
  return useMutation<WebGebruikerInput, Error, WebGebruikerInput>({
    mutationFn: (newGebruiker: WebGebruikerInput) =>
      fetchAuthenticated<WebGebruikerInput>(`${BASE_URL}`, {
        json: newGebruiker,
        method: 'POST',
      }),
    mutationKey: [...QUERY_KEY],
  });
};

export const useGetWebgebruikerById = (id: number) => {
  return useQuery({
    queryKey: [...QUERY_KEY, id],
    queryFn: () => fetchAuthenticated<WebGebruikerOutput>(`${BASE_URL}/${id}`),
    enabled: !!id,
  });
};

export const useUpdateWebgebruiker = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation<WebGebruikerInput, Error, WebGebruikerInput>({
    mutationFn: (updatedGebruiker: WebGebruikerInput) =>
      fetchAuthenticated<WebGebruikerInput>(`${BASE_URL}/${id}`, {
        json: updatedGebruiker,
        method: 'PUT',
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [...QUERY_KEY, id] });
    },
  });
};


export const useReset2FAAzureAccount = (
  options?: Omit<UseMutationOptions<string, unknown, string, unknown>, 'mutationFn'> | undefined
): UseMutationResult<string, unknown, string, unknown> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) =>
      fetchAuthenticated<string>(`${BASE_URL}/${id}/reset2fa-adb2c`, {
        method: 'POST',
      }),
    ...options,
    onSuccess: (...props) => {
      queryClient.resetQueries({ queryKey: [...QUERY_KEY] });
      options?.onSuccess?.(...props);
    },
  });
};

export const useRequestAzureAccount = (
  options?: Omit<UseMutationOptions<number, unknown, number, unknown>, 'mutationFn'> | undefined
): UseMutationResult<number, unknown, number, unknown> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: number) =>
      fetchAuthenticated<number>(`${BASE_URL}/${id}/request-adb2c`, {
        method: 'POST',
      }),
    ...options,
    onSuccess: (...props) => {
      queryClient.resetQueries({ queryKey: [...QUERY_KEY] });
      options?.onSuccess?.(...props);
    },
  });
};