import { useCreateNormGroepStikstof } from "@/lib/queries/config-api/cluster";
import { SignaalgroepFosfor } from "@/lib/types/cluster";
import { Form, Input, Modal } from "antd";
import { useState } from "react";

interface ModalProps {
  visible: boolean;
  onClose: () => void;
  onSave: () => void;
}

export const NormStikstofModal: React.FunctionComponent<ModalProps> = ({ visible, onClose, onSave }) => {
  const [form] = Form.useForm<SignaalgroepFosfor>();
  const [loading, setLoading] = useState(false);

  const createNormStikstof = useCreateNormGroepStikstof();

  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      setLoading(true);
      createNormStikstof.mutate({ ...values }, {
        onSuccess: () => {
          onSave();
        },
        onError: (error) => {
          setLoading(false);
        }
      })

      setLoading(false);
      onSave();
      onClose();
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={"Normgroep stikstof aanmaken"}
      open={visible}
      onCancel={onClose}
      onOk={handleSave}
      confirmLoading={loading}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="waarde"
          label="Waarde"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}