import { ClusterForm } from '@/lib/components/forms/ClusterForm';
import { useCreateCluster } from '@/lib/queries/config-api/cluster';
import { Button, Card } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export const ClusterCreate: React.FunctionComponent = () => {
  const createCluster = useCreateCluster();
  const navigate = useNavigate();

  const formId = "createClusterForm"
  return (
    <>
      <Card
        title="Cluster aanmaken"
        bordered={true}
        extra={
          <Button type="primary" htmlType="submit" form={formId}>
            Opslaan
          </Button>
        }>
        <ClusterForm
          id={formId}
          onFinish={values => {
            createCluster.mutate({ ...values }, {
              onSuccess: () => {
                navigate('/cluster');
              },
            });
          }}
        />
      </Card>
    </>
  );
};