import { createFilterParams, fetchAuthenticated } from '@/lib/utilities/api';
import { PagedResponse, PaginationQueryParameters } from '@/shared/types/general';
import { UseQueryResult, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { BevoegdGezagInput, BevoegdGezagOutput, BevoegdGezagTypeOutput } from '../../types/bevoegdgezag';

const QUERY_KEY = ['bevoegdGezagen'];
const BASE_URL = `${import.meta.env.VITE_CONFIG_API_URL}/bevoegdgezag`;

export const useGetBevoegdGezagen = (params?: PaginationQueryParameters<BevoegdGezagOutput>): UseQueryResult<PagedResponse<BevoegdGezagOutput>, Error> => {
  return useQuery({
    queryKey: [...QUERY_KEY, params],
    queryFn: () => fetchAuthenticated<PagedResponse<BevoegdGezagOutput>>(`${BASE_URL}?${createFilterParams(params ?? {})}`),
  });
};

export const useGetBevoegdGezagById = (id?: number): UseQueryResult<BevoegdGezagOutput, Error> =>
  useQuery({
    queryKey: [...QUERY_KEY, id],
    queryFn: () => fetchAuthenticated<BevoegdGezagOutput>(`${BASE_URL}/${id}`),
    enabled: !!id,
  });

export const useCreateBevoegdGezag = () => {
  return useMutation<BevoegdGezagInput, Error, BevoegdGezagInput>({
    mutationFn: (newBevoegdGezag: BevoegdGezagInput) =>
      fetchAuthenticated<BevoegdGezagInput>(`${BASE_URL}`, {
        json: newBevoegdGezag,
        method: 'POST',
      }),
    mutationKey: [...QUERY_KEY],
  });
};

export const useUpdateBevoegdGezag = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation<BevoegdGezagInput, Error, BevoegdGezagInput>({
    mutationFn: (newBevoegdGezag: BevoegdGezagInput) =>
      fetchAuthenticated<BevoegdGezagInput>(`${BASE_URL}/${id}`, {
        json: newBevoegdGezag,
        method: 'PUT',
      }),
    mutationKey: [...QUERY_KEY, id],
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [...QUERY_KEY, id] });
    },
  });
};

export const useGetBevoegdGezagTypes = (params?: PaginationQueryParameters<BevoegdGezagOutput>): UseQueryResult<BevoegdGezagTypeOutput[], Error> => {
  return useQuery({
    queryKey: [...QUERY_KEY, 'types', params],
    queryFn: () => fetchAuthenticated<BevoegdGezagTypeOutput[]>(`${BASE_URL}/types?${createFilterParams(params ?? {})}`),
  });
};