import { DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { CSSProperties, DetailedHTMLProps, FC, InputHTMLAttributes, useCallback, useEffect, useState } from 'react';
interface CustomDatePickerProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  value?: string;
  format?: string;
  onChange?: (dayjs) => void;
  style?: CSSProperties;
  picker: 'date' | 'month' | 'quarter' | 'year' | 'time' | 'week';
}

export const CustomDatePicker: FC<CustomDatePickerProps> = ({ ...props }) => {
  const { value, format, onChange, disabled, style, picker } = props;
  const [DatePickerValue, setDatePickerValue] = useState<Dayjs | null>(dayjs(value, format));
  useEffect(() => {
    if (value) {
      let dateString = value;
      let dayjsValue = dayjs(dateString);
      if (picker === 'year') {
        dateString = `01-01-${dateString}`;
        dayjsValue = dayjs(dateString);
      }
      if (format === 'DD-MM') {
        dateString += `-${dayjs().year()}`;
        dayjsValue = dayjs(dateString, 'DD-MM-YYYY');
      }
      const formattedDayjsValue = dayjs(dateString, format);
      if (dayjsValue.isValid() && dateString.length !== 10) {
        setDatePickerValue(dayjsValue);
        return;
      }
      setDatePickerValue(formattedDayjsValue);
      return;
    }
    setDatePickerValue(null);
  }, [value, format]);

  const customOnChange = useCallback(
    valueDayjs => {
      if (!valueDayjs) {
        onChange?.(undefined);
      }
      const val: Dayjs = valueDayjs;
      if (onChange) {
        if (!val) {
          onChange(null);
        }
        if (picker === 'year') {
          onChange(val.format('YYYY'));
        } else if (format === 'DD-MM') onChange(val.format('DD-MM'));
        else onChange(val.toISOString());
      }
    },
    [onChange]
  );

  return (
    <DatePicker
      picker={picker}
      style={style}
      value={DatePickerValue}
      onChange={customOnChange}
      allowClear={true}
      format={format}
      className="ant-input-number-input"
      disabled={disabled}
    />
  );
};