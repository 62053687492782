import { DeelnemerForm } from '@/lib/components/forms/DeelnemerForm';
import { constants } from '@/lib/constants';
import { useGetBevoegdGezagen } from '@/lib/queries';
import { useGetDeelnemerById, useGetParentDeelnemers, useUpdateDeelnemer } from '@/lib/queries/config-api/deelnemer';
import { Button, Card, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

export const DeelnemerEdit: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const id = Number(useParams().id?.toString());
  const updateDeelnemer = useUpdateDeelnemer(id);
  const { t } = useTranslation('core');

  const { data: deelnemerResponse, isLoading: isDeelnemerLoading } = useGetDeelnemerById(id);
  const { data: bevoegdGezagResponse, isLoading: isBevoegdGezagLoading } = useGetBevoegdGezagen({ pageSize: constants.defaultMaxPageSize });
  const { data: parentResponse, isLoading: isParentLoading } = useGetParentDeelnemers();

  const formId = 'editDeelnemerForm';
  return (
    <>
      {isDeelnemerLoading || isBevoegdGezagLoading || isParentLoading ? (
        <Spin size="large" />
      ) : (
        <Card
          title={t('deelnemer.update')}
          bordered={true}
          extra={
            <Button type="primary" htmlType="submit" form={formId}>
              Opslaan
            </Button>
          }>
          <DeelnemerForm
            id={formId}
            onFinish={values => {
              updateDeelnemer.mutate(
                {
                  ...values,
                  vestigingsNummer: values.vestigingsNummer.toString(),
                  huisNummer: values.huisNummer.toString(),
                  postadresPostcodeCijfers: values.postadresPostcodeCijfers.toString(),
                  vestigingsPostcodeCijfers: values.vestigingsPostcodeCijfers.toString(),
                  nrGemeente: values.nrGemeente?.toString(),
                  nrWaterschap: values.nrWaterschap?.toString(),
                },
                {
                  onSuccess: () => {
                    navigate('/deelnemer');
                  },
                }
              );
            }}
            initialValues={deelnemerResponse ?? []}
            bevoegdGezagen={bevoegdGezagResponse?.data ?? []}
            hoofdvestigingen={parentResponse ?? []}
          />
        </Card>
      )}
    </>
  );
};