export interface DeelnemerOutput {
  id: number;
  bedrijfsnaam: string;
  contactPersoon: string;
  actief: boolean;
  uoNummer: number;
  vestigingsStraat: string;
  vestigingsNummer: string;
  vestigingsNummerToevoeging: string;
  vestigingsPostcodeCijfers: string;
  vestigingsPostcode: string;
  vestigingsPlaats: string;
}

export enum AdresLookupStatusEnum {
  "Nog niet bewerkt" = 0,
  "Record wordt bewerkt" = 1,
  "Succesvol bewerkt" = 2,
  "Fout" = 3
}

export enum ZuiveringsplichtVanToepassingEnum {
  "Ja" = 1,
  "Nee" = 2,
  "In behandeling" = 3
}

export enum ZuiveringsplichtwijzeEnum {
  "Nullozing" = 1,
  "Individuele zuivering" = 2,
  "Mobiele zuivering" = 3,
  "Collectieve zuivering" = 4
}

export enum DeelnemerActiefEnum {
  "Actief" = 0,
  "Actief, niet rapportage- maar wel registratieplichtig" = 1,
  "Inactief" = 2,
  "Definitief inactief" = 3
}

export interface DeelnemerInput {
  id: number;
  bedrijfsnaam: string;
  contactPersoon: string;
  actief: boolean;
  vestigingsStraat: string;
  vestigingsNummer: string;
  vestigingsNummerToevoeging: string;
  vestigingsPostcode: string;
  vestigingsPlaats: string;
  telefoonnummer: string;
  email: string;
  straat: string;
  huisNummer: string;
  huisNummerToevoeging: string;
  postcode: string;
  plaats: string;
  gemeenteID: number;
  waterschapID: number;
  uoNummer: number;
  postadresPostcodeCijfers: string;
  vestigingsPostcodeCijfers: string;
  eindDatum?: Date;
  isHoofdvestiging: boolean;
  parent: number;
  maatwerkvoorschriftMagBerekenen: boolean;
  maatwerkvoorschriftTeeltwijzeCode: number;
  jaartalMaatwerkVoorschriftToegestaan: number;
  maatwerkvoorschriftDoor: string;
  deelnemerActief: number;
  toelichting: string;
  nrGemeente: string;
  nrWaterschap: string;
  zuiveringsplichtVanToepassing: ZuiveringsplichtVanToepassingEnum;
  zuiveringsplichtWijze: ZuiveringsplichtwijzeEnum;
}